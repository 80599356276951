<template>
  <b-row class="match-height">
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="UserIcon"
          :statistic="$t('campaigns.followers')"
          :statistic-title="$t('youtube.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.subscribers_count.value)}`"
          :chart-data="[{name: $t('campaigns.followers'), data: seriesSubs}]"
          :color="'successs'"
          :chartOptions="chartOptions"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="UserIcon"
          :statistic="$t('tiktok.susGrowth')"
          :statistic-title="$t('youtube.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(growthSubs)}%`"
          :chart-data="[{name: '%', data: seriesSubsGrowth}]"
          :color="'successs'"
          :chartOptions="chartOptions"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('youtube.averageViews')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('twitch.average')}: ${format(twitchData.views_avg.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.views'), data: seriesViews}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('youtube.viewRate')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('twitch.averageRate')}: ${format(twitchData.views_rate.performance.all.value)}%`"
          :chart-data="[{name: '%', data: seriesViewsRate}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('twitch.viewsVideo')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.video_views_count.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.views'), data: seriesViewsVideo}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('twitch.averageViewsVideo')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('twitch.average')}: ${format(twitchData.video_views_avg.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.views'), data: seriesViewsVideoAvg}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="EyeIcon"
          :statistic="$t('twitch.averageViewsLive')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('twitch.average')}:${format(twitchData.live_viewers_avg.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.views'), data: seriesViewsLive}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="VideoIcon"
          :statistic="$t('twitch.hoursStream')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.hours_streamed.performance.all.value)}`"
          :chart-data="[{name: $t('twitch.hours'), data: seriesStreamHours}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="VideoIcon"
          :statistic="$t('twitch.contentWeek')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.videos_per_week.performance.all.value)}`"
          :chart-data="[{name: $t('youtube.contents'), data: seriesVideosWeek}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="MessageSquareIcon"
          :statistic="$t('twitch.activeDays')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.active_days_per_week.performance.all.value)}`"
          :chart-data="[{name: $t('twitch.days'), data: seriesDaysWeek}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
    <b-col class="col-12 col-md-6 col-lg-6 col-xl-4">
      <statistic-card-with-line-chart
          icon="MessageSquareIcon"
          :statistic="$t('twitch.chatMessage')"
          :statistic-title="$t('twitch.duringlast')"
          :statisticText="`${this.$t('youtube.total')}: ${format(twitchData.chat_messages_per_hour.performance.all.value)}`"
          :chart-data="[{name: $t('twitch.days'), data: seriesChat}]"
          :color="'successs'"
          :chartOptions="options"
          :heightChart="130"
          :class="is_detail_network ? 'border-box-analytics' : ''"
        />
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import { getSeries } from '@/libs/utils/series_graphs'
import { convertData, getFormat } from '@/libs/utils/formats'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithLineChart
  },
  props: {
    twitchData: {
      type: Object,
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      getFormat,
      convertData,
      getSeries,
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['30d', '90d', '180d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
      options: {
        grid: {
          show: false,
          padding: {
            left: 30,
            right: 30,
            bottom: 30
          },
        },
        chart: {
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          width: 5,
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['7d','30d', '90d', '180d', '365d'],
          show: true,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            show: true,
            offsetY: -5,

          }
        },
        colors: ['#A9A2F6'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#A9A2F6'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 2,
          },
        },
        tooltip: {
          enabled: true,
          x: { show: true },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show:true,
          position: 'top'
        }
      },
    }
  },
  computed: {
    seriesSubs() {
      return getSeries(convertData(this.twitchData.subscribers_count.performance),'value')
    },
    seriesSubsGrowth() {
      return getSeries(convertData(this.twitchData.subscribers_growth_prc.performance),'value')
    },
    growthSubs() {
      const data = convertData(this.twitchData.subscribers_growth_prc.performance)
      return data.reduce((sum, i) => sum + i.value, 0)
    },
    seriesViews() {
      return getSeries(this.quitAll(this.twitchData.views_avg.performance),'value')
    },
    seriesViewsRate() {
      return getSeries(this.quitAll(this.twitchData.views_rate.performance),'value')
    },
    seriesViewsVideo() {
      return getSeries(this.quitAll(this.twitchData.video_views_count.performance),'value')
    },
    seriesViewsVideoAvg() {
      return getSeries(this.quitAll(this.twitchData.video_views_avg.performance),'value')
    },
    seriesViewsLive() {
      return getSeries(this.quitAll(this.twitchData.live_viewers_avg.performance),'value')
    },
    seriesStreamHours() {
      return getSeries(this.quitAll(this.twitchData.hours_streamed.performance),'value')
    },
    seriesVideosWeek() {
      return getSeries(this.quitAll(this.twitchData.videos_per_week.performance),'value')
    },
    seriesDaysWeek() {
      return getSeries(this.quitAll(this.twitchData.active_days_per_week.performance),'value')
    },
    seriesChat() {
      return getSeries(this.quitAll(this.twitchData.chat_messages_per_hour.performance),'value')
    }
  },
  methods: {
    quitAll(data) {
      const arr = convertData(data)
      arr.pop()
      return arr
    },
    format(num) {
      return getFormat(num)
    }
  },
}
</script>
